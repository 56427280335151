<template>
  <transition name="fade" appear>
    <div class="home">
      <el-row>
        <el-col :span="6">
          <div class="choice">
            <div class="choice_title">选择章节</div>
            <div class="list">
              <template v-for="(item, index) in List">
                <div
                  :class="[index == typeIndex ? 'type_item_true' : 'type_item_false']"
                  :key="index"
                  @click="change(index)"
                >
                  {{ item.chapterName }}
                </div>
              </template>
              <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <video  :src="lessonUrl" controls class="div_video" v-if="List[typeIndex].urlType == '0'" controlsList="nodownload" oncontextmenu="return false"  autoplay />
          <iframe class="div_video" :src="lessonUrl" frameborder="0" v-else-if="List[typeIndex].urlType == '1'">
          </iframe>
          <iframe
            class="div_video"
            :src="'http://view.officeapps.live.com/op/view.aspx?src=' + lessonUrl"
            frameborder="0"
            v-else
          >
          </iframe>
          <div class="title">{{ title }}</div>
          <el-row class="content">
            <!-- 点赞 -->
            <el-col :span="8">
              <div class="el_left">
                <div class="action_groupItem flex" @click="setFavorite(isUp)">
                  <div v-if="!isUp">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-dianzan"></use>
                    </svg>
                  </div>
                  <div v-else>
                    <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                      <use xlink:href="#icon-dianzan_active"></use>
                    </svg>
                  </div>
                  <div class="action_text">{{ LikeCount }}</div>
                </div>
              </div>
            </el-col>
            <!-- 收藏 -->
            <el-col :span="8">
              <div class="el_center">
                <div class="action_groupItem flex" @click="setCollect(isCollent)">
                  <div v-if="!isCollent">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-shoucang"></use>
                    </svg>
                  </div>
                  <div v-else>
                    <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                      <use xlink:href="#icon-shoucang_active"></use>
                    </svg>
                  </div>
                  <div class="action_text">{{ CollectCount }}</div>
                </div>
              </div>
            </el-col>
            <!-- 转发 -->
            <el-col :span="8">
              <div class="el_right">
                <div class="action_groupItem flex" @click="copy">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-fenxiang"></use>
                  </svg>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>    
    </div>  
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  name: "curriculumDetail",
  data() {
    return {
      title: "公共管理学课程教学大纲",
      lessonUrl: "",
      //点赞
      isUp: false,
      //收藏
      isCollent: false,
      typeIndex: 0,
      List: [],
      courseCode: "",
      LikeTemporary: {},
      LikeCount: "",
      CollectTemporary: {},
      CollectCount: "",
      userInfo: {},
      logo:"1",
    };
  },
  methods: {
    //查询课程的收藏数和该用户有没有收藏
    getCollectCount() {
      var getData = {};
      getData.courseCode = this.courseCode;
      getData.chapterCode = this.List[this.typeIndex].chapterCode;
      getData.memberCode = this.userInfo.memberCode;
      this.$api.lesson.getCollectCount(getData).then((res) => {
        if (res.data.code === 200) {
          this.CollectTemporary = res.data.data;
          this.CollectCount = res.data.data.count;
          if (res.data.data.chapterCode) {
            this.isCollent = true;
          } else {
            this.isCollent = false;
          }
        }
      });
    },
    //查询课程的点赞数和该用户有没有点赞
    getFavoriteCount() {
      var getData = {};
      getData.courseCode = this.courseCode;
      getData.chapterCode = this.List[this.typeIndex].chapterCode;
      getData.memberCode = this.userInfo.memberCode;
      
      this.$api.lesson.getFavoriteCount(getData).then((res) => {
        if (res.data.code === 200) {
          this.LikeTemporary = res.data.data;
          this.LikeCount = res.data.data.count;
          if (res.data.data.chapterCode) {
            this.isUp = true;
          } else {
            this.isUp = false;
          }
        }
      });
    },
    //课程详情
    getCourseDetail() {
      var getData = {};
      var condition = {};
      condition.courseCode = this.courseCode;
      getData.condition = condition;
      this.$api.lesson.getCourseDetail(getData).then((res) => {
        if (res.data.code === 200) {
          this.List = res.data.data.data[0].courseChapterList;
          this.title = res.data.data.data[0].courseName;
          this.lessonUrl = this.List[0].url;
          this.getFavoriteCount();
          this.getCollectCount();
          // console.log('课程', this.List)
        }
      });
    },
    //点赞
    setFavorite(isUp) {
      var getData = {};
      getData.courseCode = this.courseCode;
      getData.chapterCode = this.List[this.typeIndex].chapterCode;
      getData.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
      this.$api.lesson.setFavorite(getData).then((res) => {
        if (res.data.code === 200) {
          // console.log("恭喜你，点赞成功啦！");
        }
      });
      if (isUp) {
        this.isUp = false;
        this.LikeCount--;
      } else {
        this.isUp = true;
        this.LikeCount++;
        var getData = {};
      }
    },
    //收藏
    setCollect(isCollent) {
      var getData = {};
      getData.nikeName = this.userInfo.name;
      getData.courseCode = this.courseCode;
      getData.chapterCode = this.List[this.typeIndex].chapterCode;
      getData.memberCode = this.userInfo.memberCode;
      getData.logo = this.logo;
      this.$api.lesson.setCollect(getData).then((res) => {
        if (res.data.code === 200) {
          // console.log("恭喜你，收藏成功啦！");
        }
      });
      if (isCollent) {
        this.isCollent = false;
        this.CollectCount--;
      } else {
        this.isCollent = true;
        this.CollectCount++;
      }
      //   this.$api.lesson.addCollect({
      //     memberCode: this.memberCode,
      //     courseCode: obj.courseCode
      //   }).then(res => {
      //     if (res.data.code === 200) {
      //       obj.isCollent = !obj.isCollent
      //     }
      //   })
    },

    //分享
    setShare() {
      if (!this.video) {
        // this.$message.error("该内容暂不可分享");
        this.$message({
            type: "error",
            message: "该内容暂不可分享",
            offset:'400',
          });
        return;
      }
      //创建input标签
      let input = document.createElement("input");
      //将input的值设置为需要复制的内容
      // input.value = "http://view.officeapps.live.com/op/view.aspx?src=" + this.video;
      input.value = window.location.href;
      //添加input标签
      document.body.appendChild(input);
      //选中input标签
      input.select();
      //执行复制
      document.execCommand("copy");
      //移除input标签
      document.body.removeChild(input);
      // this.$message.success("图文地址复制成功");
      this.$message({
            type: "success",
            message: "图文地址复制成功",
            offset:'400',
          });
    },
    //分享
    copy () {
      let invitelink = location.href;
      this.$copyText(invitelink).then(
        res => {
          this.$notify({
          title:'已复制到剪切板了',
          message:'分享给您的好友',
          type:'seccess',
          offset:300,
          duration:2000
        })
        },
        err => {
          console.log(err) // 同上
        }
      )
    },
    //切换课程
    change(index) {
      this.typeIndex = index;
      this.lessonUrl = this.List[index].url;
      this.getCollectCount();
      this.getFavoriteCount();
    },
     //禁用F12
    forbidden(){
       document.onkeydown = function() {
        var e = window.event || arguments[0];
        if (e.keyCode == 123) {
            return false;
        }
    }
    },
  //禁用右键
    forRightClick(){
      document.oncontextmenu = function() {
        return false;
    }
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.$store.commit(CHANGE_NAVINDEX, "1");
    this.courseCode = this.$route.query.courseCode;
    this.logo = this.$route.query.logo;
    this.getCourseDetail();
    this. forbidden()
    this.forRightClick()
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 100%;
  margin-top: 40px;
  background-color: white;
  .div_video {
    width: 100%;
    height: 600px;
  }
  .title {
    font-size: 22px;
    margin-top: 15px;
    margin-left: 20px;
    text-align: left;
  }
  .content {
    margin: 15px 0 0 20px;
  }
  .el_left {
    text-align: left;
  }
  .el_center {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .el_right {
    display: flex;
    justify-content: right;
    text-align: right;
  }
  .action_groupItem {
    display: flex;
    align-items: center;
  }
  .icon {
    width: 30px;
    height: 30px;
  }
  .icon:hover {
    cursor: pointer;
  }
  .action_text {
    font-size: 22px;
    margin-left: 5px;
  }
  .choice {
    margin: 0 30px 0 0;
    height: 600px;
    .choice_title {
      padding: 11px 0 11px 21px;
      font-size: 25px;
      background-color: #f6f7f6;
      text-align: left;
    }
    .list {
      max-height: 100%;
      overflow: auto;
    }
  }
  .type_item_true {
    padding: 11px 0 11px 21px;
    font-size: 18px;
    color: #fca823;
    border-bottom: 1px dotted #dddddd;
    text-align: left;
  }
  .type_item_true:hover{
    cursor: pointer;
  }
  .type_item_false {
    padding: 11px 0 11px 21px;
    font-size: 18px;
    color: #1e1e1e;
    border-bottom: 1px dotted #dddddd;
    text-align: left;
  }
   .type_item_false:hover{
    cursor: pointer;
  }
}
</style>
